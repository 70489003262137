<template>
    <div :class="data.Class" :style="data.Style">
        <div :class="data.ContainerClass">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="p-5" v-for="(head, n) in data.Data.Config" :key="n">{{ head.headerName }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr height="20px" v-for="(tr, indextr) in data.Data.Data" :key="indextr">
                        <td class="py-2 p-5" v-for="(body, n) in data.Data.Config" :key="n">
                            {{ tr[body.field] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
<style lang="scss" scoped>
    tbody tr:hover {
        transition: 0.2s;
        transform: translate(5px, -5px);
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, .25);
    }
    td {
        vertical-align: middle !important;
    }
    table {
        border: none;
        border-collapse: separate;
        border-spacing: 0 5px;
    }
</style>
